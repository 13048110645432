var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "primary--text font-weight-bold" }, [
          _vm._v("File Compare")
        ])
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            {
              staticStyle: { "min-height": "60px" },
              attrs: { align: "center" }
            },
            [
              _c("v-col", { attrs: { cols: "2" } }, [_vm._v("File name")]),
              _c("v-col", { attrs: { cols: "10" } }, [
                _c("span", { staticClass: "primary--text font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.currentFileUnderRevisionData.fileName))
                ])
              ])
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticStyle: { "min-height": "60px" },
              attrs: { align: "center" }
            },
            [
              _c("v-col", { attrs: { cols: "2" } }, [
                _vm._v(" Current version ")
              ]),
              _c(
                "v-col",
                { attrs: { cols: "10" } },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "ma-0 pa-0",
                    attrs: { items: _vm.breadcrumbsItemsFirstLevel },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function(props) {
                          return [
                            _c(
                              "v-breadcrumbs-item",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "primary--text font-weight-bold"
                                  },
                                  [_vm._v(_vm._s(props.item.text))]
                                ),
                                props.item.index === 2
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass: "ml-1",
                                                        attrs: { color: "info" }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "mdi-information-outline"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "This is the current version of your file."
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "divider",
                        fn: function() {
                          return [
                            _c("v-icon", [_vm._v("mdi-chevron-double-right")])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticStyle: { "min-height": "60px" },
              attrs: { align: "center" }
            },
            [
              _c("v-col", { attrs: { cols: "2" } }, [
                _vm._v("Previous version")
              ]),
              _c(
                "v-col",
                { attrs: { cols: "10" } },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "ma-0 pa-0",
                    attrs: { items: _vm.breadcrumbsItemsSecondLevel },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function(props) {
                          return [
                            _c(
                              "v-breadcrumbs-item",
                              [
                                props.item.index === 2
                                  ? _c(
                                      "v-menu",
                                      {
                                        attrs: { "offset-y": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          class:
                                                            _vm.selectedSnapshot ===
                                                            "select snapshot"
                                                              ? "warning--text font-weight-bold"
                                                              : "primary--text font-weight-bold"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                props.item.text
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _vm.selectedSnapshot !==
                                                      "select snapshot"
                                                        ? _c(
                                                            "v-dialog",
                                                            {
                                                              attrs: {
                                                                "max-width":
                                                                  "500"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var dialog =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              bottom:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var tooltip =
                                                                                      ref.on
                                                                                    return [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        _vm._g(
                                                                                          {
                                                                                            attrs: {
                                                                                              color:
                                                                                                "error",
                                                                                              icon:
                                                                                                "",
                                                                                              disabled:
                                                                                                _vm.disableRestore,
                                                                                              loading:
                                                                                                _vm.restoreLoading
                                                                                            }
                                                                                          },
                                                                                          Object.assign(
                                                                                            {},
                                                                                            tooltip,
                                                                                            dialog
                                                                                          )
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-restore-alert"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "Restore"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm.restoreDialog,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.restoreDialog = $$v
                                                                },
                                                                expression:
                                                                  "restoreDialog"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                [
                                                                  _c(
                                                                    "v-card-title",
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "primary--text"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-1"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-restore-alert"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            "File Restore"
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-divider",
                                                                    {
                                                                      staticClass:
                                                                        "mb-1"
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "v-card-text",
                                                                    [
                                                                      _vm._v(
                                                                        " Restoring a previous version of a file will replace the current version of the file in your current state with the previous. "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-card-actions",
                                                                    [
                                                                      _c(
                                                                        "v-spacer"
                                                                      ),
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "error",
                                                                            text:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.restoreFile()
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " restore this version "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "primary",
                                                                            text:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              _vm.restoreDialog = false
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " cancel "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          small: "",
                                                          icon: "",
                                                          loading:
                                                            _vm.compareLoading
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "keyboard_arrow_down"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          {
                                            attrs: { dense: "" },
                                            model: {
                                              value: _vm.selectedSnapshot,
                                              callback: function($$v) {
                                                _vm.selectedSnapshot = $$v
                                              },
                                              expression: "selectedSnapshot"
                                            }
                                          },
                                          _vm._l(_vm.snapshotNames, function(
                                            item
                                          ) {
                                            return _c(
                                              "v-list-item",
                                              {
                                                key: item,
                                                on: {
                                                  click: function($event) {
                                                    return _vm.compareFiles(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(_vm._s(item))
                                                ])
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass:
                                          "primary--text font-weight-bold"
                                      },
                                      [_vm._v(_vm._s(props.item.text))]
                                    )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "divider",
                        fn: function() {
                          return [
                            _c("v-icon", [_vm._v("mdi-chevron-double-right")])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.diffs
            ? _c("div", { domProps: { innerHTML: _vm._s(_vm.prettyHtml) } })
            : _vm.selectedSnapshot !== "select snapshot" &&
              _vm.compareError === false &&
              _vm.tooBigToCompare === false &&
              _vm.compareLoading === false
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-5",
                  attrs: { prominent: "", type: "info", text: "", dense: "" }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v(
                          "The file version you have selected is identical to what you currently have."
                        )
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                to: {
                                  name: "snapshot-files",
                                  params: {
                                    oid: _vm.$route.params.oid,
                                    sid: _vm.$route.params.sid,
                                    iid: _vm.$route.params.iid,
                                    snid: _vm.$route.params.snid,
                                    localPath: _vm.currentFileLocalPath,
                                    fileArea: _vm.fileAreaType
                                  }
                                },
                                text: "",
                                exact: "",
                                color: "primary"
                              }
                            },
                            [_vm._v("go back to files")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.selectedSnapshot !== "select snapshot" &&
              _vm.compareError === true &&
              _vm.compareLoading === false
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-5",
                  attrs: { prominent: "", type: "warning", text: "", dense: "" }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v(
                          "An error has occurred. Refresh the page or try again later."
                        )
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                to: {
                                  name: "snapshot-files",
                                  params: {
                                    oid: _vm.$route.params.oid,
                                    sid: _vm.$route.params.sid,
                                    iid: _vm.$route.params.iid,
                                    snid: _vm.$route.params.snid,
                                    localPath: _vm.currentFileLocalPath,
                                    fileArea: _vm.fileAreaType
                                  }
                                },
                                text: "",
                                exact: "",
                                color: "primary"
                              }
                            },
                            [_vm._v("go back to files")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.selectedSnapshot !== "select snapshot" &&
              _vm.tooBigToCompare === true &&
              _vm.compareLoading === false
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-5",
                  attrs: { prominent: "", type: "warning", text: "", dense: "" }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v(
                          "File comparison has failed due to the large size of the file."
                        )
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                to: {
                                  name: "snapshot-files",
                                  params: {
                                    oid: _vm.$route.params.oid,
                                    sid: _vm.$route.params.sid,
                                    iid: _vm.$route.params.iid,
                                    snid: _vm.$route.params.snid,
                                    localPath: _vm.currentFileLocalPath,
                                    fileArea: _vm.fileAreaType
                                  }
                                },
                                text: "",
                                exact: "",
                                color: "primary"
                              }
                            },
                            [_vm._v("go back to files")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }